import React from "react";
import Abc from "../image/404.svg";

const ErrorPage = () => {
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "50vh",
        }}
      >
        <div>
          <img alt="404" src={Abc}></img>
          <br></br>
        </div>
      </div>

      <div style={{
         textAlign: "center"
        }}>
        <h1 style={{color: "#91288c"}}>Oops, Page Not Found</h1>

        <br></br>
        
        <p>
          Sorry the page you are looking for cannot be found. You might have
          typed <br></br> wrong URL or have lost the internet connection.
        </p>
      
        <p style={{color: "rgb(169, 169, 169)"}}>You can do any of the below actions.</p>
       
        <p>1) Check your internet connection or page URL</p>
        <p>2) Go Back to <a style={{color:"#91288c"}} href="https://oneloadpk.com">Home</a> Page</p>
      </div>
    </>
  );
};

export default ErrorPage;
